

import GA4Service from "@/services/resources/GA4Service";
import gtagSend from '@/utils/gtagSend.js';

const serviceGA4 = GA4Service.build();
export default {
  async getSenciData({commit}){
    await this.dispatch("getCurrentSiteAndMember")

    const currentSite = this.state.user.currentSite
    if(currentSite.currentSite.role == 'owner'){
      serviceGA4.read(currentSite.currentSite.id).then((resp)=>{
        gtagSend('greenn_club_inicial_acessado',resp.data)
        commit("setInitLoading", resp.data);
      })
    }
  },
}