export default function notificacao(tipo, mensagem, tempo) {
    const boxNotificacao = document.querySelector('.box-notificacoes');
    setTimeout(() => {
        const div = document.createElement('div');
        div.classList.add(`notificacao-${tipo}`);
        div.classList.add('ativo');
        div.innerHTML = `<h3 class="notificacao-text-color">${mensagem}</h3>`;
        boxNotificacao.appendChild(div);

        div.addEventListener('click', removeNotificacao);

        setTimeout(() => {
            removeNotificacao();
        }, tempo || 6300);

        function removeNotificacao() {
            div.remove();
        }
    }, 1000);

}