import Cookies from "js-cookie";

export default {
  ClubTheme: (state, theme) => {
    state.theme = theme || "light";
    Cookies.set("themeClub", theme, {
      expires: 365,
    });
  },

  clubVersion: (state, version) => {
    state.clubVersion = version;
    Cookies.set("clubVersion", version, { expires: 365 });
  },
  setInitLoading: (state, payload) => {
    state.initLoading = payload;
  }
};
