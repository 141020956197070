<template >
  <b-modal
    :id="name"
    :size="size"
    @shown="shown"
    @hidden="hidden"
  >
    <h1 class="title-modal"><star/>{{ title }}</h1>
    <div class="line"></div>
    <slot></slot>

    <template v-slot:modal-footer="{ cancel }">
      <slot name="footer" :cancel="cancel"> </slot>
    </template>
  </b-modal>
</template>
<script>
import star from '@/components/icons/star.vue';
export default {
  components:{star},
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "lg",
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    shown() {
      this.$emit("shown");
      document.querySelector("body").style = "overflow: hidden;";
    },
    hidden() {
      this.$emit("hidden");
      document.querySelector("body").style = "overflow: auto !important; ";
    },
  },
};
</script>

<style>
.modal {
  overflow: auto;
}

.title-modal {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 15px;
}

.title-modal svg{
  margin-right: 10px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 15px;
}

.modal-content {
  padding: 20px 30px !important;
  animation: showTop 0.4s forwards !important;
}
.modal-content .modal-header {
  border-bottom: 1px solid #ededf0;
  border-radius: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.modal-body {
  padding: 20px 0;
}
.col-auto {
  padding: 0;
}
.modal-content .modal-header .close {
  background: url("../../assets/icons/fechar.svg") no-repeat center center;
  overflow: hidden;
  text-indent: -100px;
  color: transparent;
  outline: none;
  border: none;
}
.modal-content .modal-footer {
  border-top: 1px solid #ededf0;
  border-radius: 0;
  padding-top: 25px;
}

/* modal 100% */
#product-edit {
  padding: 0 !important;
}
#product-edit .modal-xl {
  margin: 0 auto;
  max-width: 100vw;
}
@media (min-width: 1600px) {
  #product-edit .modal-xl {
    max-width: 1300px;
  }
}
#product-edit .modal-content {
  border-radius: 0 !important;
}
#modal-info-aprovacao footer {
  display: none !important;
}
</style>