import Rest from '@/services/Rest';

/**
 * @typedef {GA4Service}
 */

export default class GA4Service extends Rest {
  /**
   * @type {String}
   */
  static resource = 'ga4'
}