
import actions from '@/store/modules/ga4/actions'
import getters from '@/store/modules/ga4/getters';
import mutations from '@/store/modules/ga4/mutations'

const state = {
    gTagData: {}
}

export {
    state,
    getters,
    actions,
    mutations,
}
