import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err
    });
}

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes
});

export default router;