import Cookies from 'js-cookie';
import axios from 'axios';
import AuthService from '@/services/resources/AuthService';
import { faStripeS } from '@fortawesome/free-brands-svg-icons';
import router from '@/router/index';
import MemberService from "@/services/resources/MemberService";

export default {

    //fazer login do OAuth 2
    loginRequest(context, payload) {
        var service = AuthService.build();
        return new Promise((resolve, reject) => {
            service.login(payload.username, payload.password, payload.captcha)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    Cookies.remove('auth_greennCourse');
                    reject(err);
                })
        })
    },
    updateUserCustomFields: function ({ commit }, payload) {
        commit('userCustomFields', true);
    },
    blogSelected(context, payload) {

        let routerIsLiberated = true;
        
        Cookies.remove('auth_greennCourse');
        Cookies.remove('access_level');
        Cookies.remove('userCustomFields');
        
        const assign = 'auth_greennCourse' + "=" + escape(payload.Auth) + ";";
        const assign2 = 'access_level' + "=" + escape(payload.role) + ";";
        const assign3 = 'userCustomFields' + "=" + escape(payload.custom_fields_filled) + ";";
        
        const d = new Date();
        
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString() + ";";
        const path = "path=/;";
        var domain = "";
        if (process.env.VUE_APP_TYPE === 'DEV') {
            domain = "domain=" + 'localhost' + ";";
        } else if (process.env.VUE_APP_TYPE === 'PROD' && payload.domains.length === 0 && (payload.role === 'owner' || payload.role === 'admin')) {
            domain = "domain=" + "app.greenn.club" + ";";
        } else if (process.env.VUE_APP_TYPE === 'PROD' && payload.domains.length === 0 && (payload.role === 'student')) {
            domain = "domain=" + payload.subdomain + '.greenn.club' + ";";
        } else if (process.env.VUE_APP_TYPE === 'PROD' && payload.domains.length !== 0) {
            const domains = payload.domains;
            let xxxDomains = domains.filter(function (e) {
                return e.domain === "https://" + window.location.host;
            });
            if (xxxDomains.length !== 0) {
                let domainActual = xxxDomains[0].domain;
                let replaceDomain = domainActual.replace('https://', '')
                domain = "domain=" + replaceDomain + ";";
            } else {
                domain = "domain=app.greenn.club;";
            }
        }

        sessionStorage.removeItem('sessionID');
        document.cookie = assign + expires + path + domain;
        document.cookie = assign2 + expires + path + domain;
        document.cookie = assign3 + expires + path + domain;


        context.commit('authSuccess', payload.Auth);
        context.commit('userCustomFields', payload.custom_fields_filled);
        context.dispatch('getMetasSite');
        context.dispatch('getCertificateConfig');

        if (process.env.VUE_APP_TYPE === 'DEV') {
            if (payload.course_id !== undefined && payload.course_id !== null) {
                // window.location.href = "http://" + window.location.host + "/curso/" + payload.course_id;
                router.push({ path: `curso/${payload.course_id}` })
            } else {
                // window.location.href = "http://" + window.location.host + "/home";
                router.push({ path: 'home' })
            }
        } else if (process.env.VUE_APP_TYPE === 'PROD' && (payload.role === 'owner' || payload.role === 'admin')) {
            var domainTo = window.location.host;
            if (domainTo.search("app.greenn.club") === -1) {
                routerIsLiberated = false;
                let auth = { payload }
                axios.post(process.env.VUE_APP_API_HOST + `/member/auth`, { auth })
                    .then(function (resp) {
                        // routerIsLiberated = true;
                        // console.log("redirect subdomain project", resp);
                        window.location.href = "https://app.greenn.club?rdr=" + resp.data.string;
                        // router.go();
                    })
            } else {
                if (payload.course_id !== undefined && payload.course_id !== null) {
                    window.location.href = "http://app.greenn.club/curso/" + payload.course_id;
                } else {
                    window.location.href = "https://app.greenn.club/home";
                }
            }
        } else if (process.env.VUE_APP_TYPE === 'PROD' && payload.domains.length === 0 && payload.role === 'student') {
            const regex = new RegExp('(greenn.club)', 'gi');
            let result = regex.exec(window.location.host);
            // console.log("res", result)
            routerIsLiberated = false
            if (result === null || window.location.host === "app.greenn.club") {
                // console.log("entrou nesse 1")
                let auth = { payload }
                axios.post(process.env.VUE_APP_API_HOST + `/member/auth`, { auth })
                .then(function (resp) {
                    // routerIsLiberated = true;
                    // console.log("redirect subdomain project", resp);
                    window.location.href = "https://" + payload.subdomain + ".greenn.club?rdr=" + resp.data.string;
                    // router.go();
                })
                .catch(e => {
                    console.log("eeee", e)
                })
            } else {
                // console.log("entrou nesse 2")
                if (payload.course_id !== undefined && payload.course_id !== null) {
                    window.location.href = "https://" + payload.subdomain + ".greenn.club/curso/" + payload.course_id;
                } else {
                    window.location.href = "https://" + payload.subdomain + ".greenn.club/home";
                }
            }
        } else if (process.env.VUE_APP_TYPE === 'PROD' && payload.domains.length !== 0) {
            const domains = payload.domains;
            let xxxDomains = domains.filter(function (e) {
                return e.domain === "https://" + window.location.host;
            });

            if (xxxDomains.length === 0) {
                routerIsLiberated = false;
                let auth = { payload }
                axios.post(process.env.VUE_APP_API_HOST + `/member/auth`, { auth })
                    .then(function (resp) {
                        // routerIsLiberated = true;
                        // console.log("redirect domain project", resp);
                        window.location.href = payload.domains[0].domain + "?rdr=" + resp.data.string;
                        // router.go();
                    })
            } else {
                if (payload.course_id !== undefined && payload.course_id !== null) {
                    window.location.href = xxxDomains[0].domain + "/curso/" + payload.course_id;
                } else {
                    window.location.href = xxxDomains[0].domain + "/home";
                }
            }
        }

        if (routerIsLiberated) {
            setTimeout(() => {
                router.go();
            }, 500)
        }
    },
    logoutRequest: ({ commit, dispatch }) => {

        // Since the logout process not need to make a request
        // just remove the cookies and redirect
        commit('authLogout');
        commit('userLogout');

    }
}
