import Cookies from "js-cookie";
import router from '@/router/index';
import MemberService from "@/services/resources/MemberService";
import CertifyService from "@/services/resources/CertifyService";
const serviceMember = MemberService.build();

export default {
    getMetasSite: ({
        commit,
        dispatch
    }) => {
        return new Promise((resolve, reject) => {
            dispatch('fetchMetas', [
                "onboarding",
                "comment_review",
                "helper_data",
                "main_color",
                "logo", 
                "theme_fixed_painel", 
                "theme_fixed_painel_color", 
                "termsOfUseActivated", 
                "logomarca", 
                "panel_background_image", 
                "panel_background_image_mobile"
            ], { root: true })
                .then((resp) => {
                    commit('metasSite', resp);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCertificateConfig: ({
        commit,
        dispatch
    }) => {
        const serviceCertificate = CertifyService.build();
        return new Promise((resolve, reject) => {
            serviceCertificate.read("check-exist")
                .then((resp) => {
                    commit('certificateConfig', resp);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCurrentSiteAndMember: async ({
        commit,
    }) => {
        commit('setAllSites', []);
        commit('currentSite', Cookies.get('currentSite') ? JSON.parse(Cookies.get('currentSite')) : {
            currentMember: {},
            currentSite: {},
        },);
        commit('setCurrentMember', {});
        return await serviceMember.read("/me")
            .then((resp) => {
                commit('setAllSites', resp.member.sites);
                commit('currentSite', resp.current);
                commit('setCurrentMember', resp);
            })
            .finally(() => {
                commit("setInitLoading", false);
            });
    },
    userRequest: ({ dispatch }) => {
        dispatch('redirectUserToHome');
    },
    redirectUserToHome: () => {
        router.go(0);
    },
    startDragging({ commit }) {
        commit('setIsDragging', true);
    },
    stopDragging({ commit }) {
        commit('setIsDragging', false);
    }
}