import Cookies from 'js-cookie';


import actions from '@/store/modules/auth/actions'
import getters from '@/store/modules/auth/getters';
import mutations from '@/store/modules/auth/mutations'

const state = {
    auth_greennCourse: Cookies.get('auth_greennCourse') || '',
    userCustomFields: (String(Cookies.get('userCustomFields')) == "true"),
    allSites: {}
}

export {
    state,
    getters,
    mutations,
    actions
}