/* Multiple guards support based on https://github.com/atanas-angelov-dev/vue-router-multiguard
  and https://github.com/maoberlehner/implementing-a-simple-middleware-with-vue-router */

export default function check(to, from, next) {

  if(from.query.globalToken){
  
    to.query.globalToken = from.query.globalToken;

  }
  return next();

}
