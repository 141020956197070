export default {
  undo: {
    toolbar: 'Desfazer',
  },
  redo: {
    toolbar: 'Refazer',
  },
  clear: {
    toolbar: 'Limpar',
  },
  h: {
    toolbar: 'Títulos',
  },
  h1: {
    toolbar: 'Título 1',
    placeholder: 'Título 1',
  },
  h2: {
    toolbar: 'Título 2',
    placeholder: 'Título 2',
  },
  h3: {
    toolbar: 'Título 3',
    placeholder: 'Título 3',
  },
  h4: {
    toolbar: 'Título 4',
    placeholder: 'Título 4',
  },
  h5: {
    toolbar: 'Título 5',
    placeholder: 'Título 5',
  },
  h6: {
    toolbar: 'Título 6',
    placeholder: 'Título 6',
  },
  bold: {
    toolbar: 'Negríto',
    placeholder: 'Negríto',
  },
  italic: {
    toolbar: 'Itálico',
    placeholder: 'Itálico',
  },
  strikethrough: {
    toolbar: 'Sublinhado',
    placeholder: 'Sublinhado',
  },
  quote: {
    toolbar: 'Citação',
    placeholder: 'Citação',
  },
  ul: {
    toolbar: 'Lista não Ordenada',
    placeholder: 'Lista não Ordenada',
  },
  ol: {
    toolbar: 'Lista Ordenada',
    placeholder: 'Lista Ordenada',
  },
  table: {
    toolbar: 'Inserir Tabela',
  },
  hr: {
    toolbar: 'Linha',
  },
  link: {
    toolbar: 'Inserir Link',
    descPlaceholder: 'Link',
  },
  image: {
    toolbar: 'Inserir imagem',
  },
  imageLink: {
    toolbar: 'Inserir link',
  },
  uploadImage: {
    toolbar: 'Subir Imagem',
  },
  code: {
    toolbar: 'Inserir Bloco de Código',
  },
  save: {
    toolbar: 'Salvar',
  },
  preview: {
    enabled: 'Habilitar visualização',
    disabled: 'Desabilitar visualização',
  },

  syncScroll: {
    enabled: 'Sincronizar scroll',
    disabled: 'Não sincronizar scroll',
  },

};