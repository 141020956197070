import Rest from '@/services/Rest';

/**
 * @typedef {SiteService}
 */
export default class SiteService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'site'
}