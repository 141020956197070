import Cookies from 'js-cookie';
import router from '@/router/index'

var delete_cookie = function (name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = name + '=;path=/; domain=.greenn.club;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = name + '=;path=/; domain=greenn.club;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = name + '=;path=/; domain=.' + window.location.host + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie = name + '=;path=/; domain=' + window.location.host + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export default {
  metasSite: (state, resp) => {
    state.metasSite = resp;
    Cookies.set('metasSite', JSON.stringify(resp), {
      expires: 365
    });
  },

  certificateConfig: (state, resp) => {
    state.certificateConfig = resp;
    Cookies.set('certificateConfig', JSON.stringify(resp), {
      expires: 365
    });
  },
  setAllSites: (state, resp) => {
    state.allSites = resp;
  },
  setIsDragging: (state, resp) => {
    state.isDragging = resp;
  },
  setCurrentMember: (state, resp) => {
    state.currentMember = resp;

  },
  currentSite: (state, resp) => {
    state.currentSite = resp;
    Cookies.set('currentSite', JSON.stringify(resp), {
      expires: 365
    });
  },

  loginSuccess: (state, resp) => {
    Cookies.set('sites', JSON.stringify(resp), { expires: 365 });
  },

  userLogout: (state) => {
    state.user = null;
    state.userCustomFields = null;
    state.blog = null;
    state.blogs = [];
    state.tags = [];
    state.resources = {};

    delete_cookie('sites');
    delete_cookie('userCustomFields');
    delete_cookie('memberAuth');
    delete_cookie('access_level');
    delete_cookie('auth_greennCourse');
    delete_cookie('blogsClub');

    sessionStorage.clear();
    localStorage.clear();

    let permission = Cookies.get('access_level');

    if (permission === "admin" || permission === "owner") {
      window.$crisp.push(['do', 'chat:hide']);

      if (window.innerWidth >= 576) {
        document.getElementById("userreport-launcher-script").remove();
      }
    }
    if (process.env.VUE_APP_TYPE === 'DEV') {
      window.location.href = "http://" + window.location.host;
    } else {
      window.location.href = "https://" + window.location.host
    }
  }

}