export default {
    authSuccess: (state, auth_greennCourse) => {
        state.auth_greennCourse = auth_greennCourse;
    },
    authLogout: (state) => {
        state.auth_greennCourse = null;
    },
    userCustomFields: (state, resp) => {
        state.userCustomFields = resp;
    },
}