import Cookies from "js-cookie";

import actions from "@/store/modules/globalSettings/actions";
import getters from "@/store/modules/globalSettings/getters";
import mutations from "@/store/modules/globalSettings/mutations";

const state = {
  theme: Cookies.get("themeClub") || "light",
  clubVersion: Cookies.get("clubVersion") || "v1",
  isMobile: null,
  initLoading: true
};

export { state, getters, mutations, actions };
