import Cookies from "js-cookie";

const getUserRole = () => {
  const typeView = Cookies.get("type_student");
  if(typeView === '1') return 'student'
  if(typeView === '0') return 'owner'
  return this.$store.getters['currentSite'].currentMember.role
}

export default {
  memberPlanStatus: "paid",
  isExceededStorage: Cookies.get('exceeded_storage') ? JSON.parse(Cookies.get('exceeded_storage')) : false,
  userRole: getUserRole,
  playerStatus: "started", // playing | paused | ended | loading | ready(only if isMobile)
  playerConfig: {
    disable_comments_courses: 'started',
  },
  isMobile: false,
  currentCourse: {},
  currentModule: {},
  currentModules: [],
  currentModuleLessons: [],
  currentLesson: {},
  currentLessonComments: [],
  // 
  lessonCommentstotalPage:1,
  lessonCommentsCurrentPage:1,
  lessonCommentsLoading: false,
  // 
  nextLesson: {},
  nextLessonForContinue: {},
  prevLesson: {},
  nextModule: {},
  prevModule: {},
  hasNextModule: false,
  hasNextLesson: false,
  hasPrevLesson: false,
  hasPrevModule: false,
  hasNextLessonForContinue: false,
  isAutoplay: false,
}